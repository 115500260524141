// This file was autogenerated. Please do not change.
export interface IRedeemRequest {
    accountId: string;
    placementName: string;
    positionId: string;
}

export default class RedeemRequest {
    readonly _accountId: string;

    /**
     * Example: AKP2W4P2VHE
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _placementName: string;

    /**
     * Example: Binance
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _positionId: string;

    /**
     * Example: SP1234123421341243
     */
    get positionId(): string {
        return this._positionId;
    }

    constructor(props: IRedeemRequest) {
        this._accountId = props.accountId.trim();
        this._placementName = props.placementName.trim();
        this._positionId = props.positionId.trim();
    }

    serialize(): IRedeemRequest {
        const data: IRedeemRequest = {
            accountId: this._accountId,
            placementName: this._placementName,
            positionId: this._positionId,
        };
        return data;
    }

    toJSON(): IRedeemRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            positionId: typeof this._positionId === 'string' && !!this._positionId.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
